import { TranslateResult } from "vue-i18n";
import { AvatarProps } from "../Avatar";

export interface DropdownProps {
    mode?: "click" | "hover";
    nostyle?: boolean;
    open?: boolean;
    expand?: "left" | "right";
    width?: string;
    maxHeight?: string;
}

export interface DropdownItemProps {
    label: string | TranslateResult;
    contentRight?: { icon?: string; iconColor?: DropdownItemColors; text?: string; textColor?: DropdownItemColors };
    labelColor?: DropdownItemColors;
    backGroundColor?: DropdownItemColors;
    iconLeft?: string;
    iconLeftColor?: DropdownItemColors;
    checked?: boolean;
    checkbox?: boolean;
    highlight?: string;
    avatar?: AvatarProps;
    loading?: boolean;
}

export interface DropdownTitleProps {
    icon: string;
    label: string | TranslateResult;
}

export interface DropdownGeneratorAction {
    type: "title" | "separator" | "item";
    item?: any;
    title?: DropdownTitleProps;
}

export interface DropdownGeneratorProps {
    actions: DropdownGeneratorAction[];
    relatedData?: any;
}

export enum DropdownItemColors {
    white = "white",
    blue = "blue",
    purple = "purple",
    red = "red",
    orange = "orange",
    yellow = "yellow",
    green = "green",
    grey = "grey",
    lightGrey = "lightGrey",
}

export type supporttedEventNames = "click" | "mouseenter" | "mouseleave" | "clickaway";

export type dropdownDirections = "automatic" | "top" | "bottom" | "left" | "right";

export interface Position {
    left: number;
    top: number;
}
