export enum AvatarColor {
    white = "white",
    blue = "blue",
    purple = "purple",
    red = "red",
    orange = "orange",
    yellow = "yellow",
    green = "green",
}

export enum AvatarSize {
    // Deprecated sizes
    xxl = "xxl",
    xl = "xl",
    lg = "lg",
    md = "md",
    sm = "sm",
    xs = "xs",
    xxs = "xxs",
    tiny = "tiny",
    xtiny = "xtiny",

    // New sizes
    size80 = "size-80",
    size72 = "size-72",
    size64 = "size-64",
    size56 = "size-56",
    size48 = "size-48",
    size40 = "size-40",
    size32 = "size-32",
    size24 = "size-24",
    size20 = "size-20",
    size16 = "size-16",
}

export interface AvatarProps {
    initials?: string;
    firstname?: string;
    lastname?: string;
    color?: keyof typeof AvatarColor;
    size?: keyof typeof AvatarSize;
    imageUrl?: string | null;
    displayName?: boolean;
}
